import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
    Content,
    Header,
    Page,
    InfoCard,
} from '@backstage/core-components';
import { BazaarOverviewCard } from '@backstage/plugin-bazaar';
import { QuestionsContainer } from '@drodil/backstage-plugin-qeta';
import { AnnouncementsCard } from '@k-phoen/backstage-plugin-announcements';

export const HomePage = () => {
    return (
        <Page themeId="homepage">
            <Header title="Homepage" />
            <Content>
                <Grid container direction="row" spacing={3}>
                    <Grid item md={12}>
                        <AnnouncementsCard max={3} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <BazaarOverviewCard title='Latest Bazaar projects' order='latest' fullWidth fullHeight />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InfoCard>
                            <QuestionsContainer
                                entity=""
                                showTitle={true}
                            />
                        </InfoCard>
                    </Grid>
                </Grid>
            </Content>
        </Page>
    )
}
