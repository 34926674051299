import { EntityBadgesDialog } from "@backstage/plugin-badges";
import { EntityLayout } from "@backstage/plugin-catalog";
import React from "react";
import { ReactNode, useState, useMemo } from "react";
import BadgeIcon from '@material-ui/icons/CallToAction';

export const EntityLayoutWrapper = (props: { children?: ReactNode }) => {
    const [badgesDialogOpen, setBadgesDialogOpen] = useState(false);
  
    const extraMenuItems = useMemo(() => {
      return [
        {
          title: 'Badges',
          Icon: BadgeIcon,
          onClick: () => setBadgesDialogOpen(true),
        },
      ];
    }, []);
  
    return (
      <>
        <EntityLayout UNSTABLE_extraContextMenuItems={extraMenuItems}>
          {props.children}
        </EntityLayout>
        <EntityBadgesDialog
          open={badgesDialogOpen}
          onClose={() => setBadgesDialogOpen(false)}
        />
      </>
    );
  };