import {
    ANNOTATION_EDIT_URL,
    ANNOTATION_LOCATION,
    stringifyEntityRef,
} from '@backstage/catalog-model';
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    IconButton,
    makeStyles,
} from '@material-ui/core';
import {
    Link,
} from '@backstage/core-components';
import React, { useCallback } from 'react';
import {
    ErrorApiError,
    alertApiRef,
    errorApiRef,
    useApi,
} from '@backstage/core-plugin-api';
import {
    catalogApiRef,
    useEntity,
} from '@backstage/plugin-catalog-react';
import CachedIcon from '@material-ui/icons/Cached';
import EditIcon from '@material-ui/icons/Edit';
import { useEntityPermission } from '@backstage/plugin-catalog-react/alpha';
import { catalogEntityRefreshPermission } from '@backstage/plugin-catalog-common/alpha';
import { AboutContent } from '@backstage/plugin-catalog';

const useStyles = makeStyles({
    gridItemCard: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 10px)', // for pages without content header
        marginBottom: '10px',
    },
    gridItemCardContent: {
        flex: 1,
    }
});

export default function EntityHtssAboutCard() {
    const classes = useStyles();
    const { entity } = useEntity();
    const catalogApi = useApi(catalogApiRef);
    const alertApi = useApi(alertApiRef);
    const errorApi = useApi(errorApiRef);
    const { allowed: canRefresh } = useEntityPermission(
        catalogEntityRefreshPermission,
    );

    const entityMetadataEditUrl =
        entity.metadata.annotations?.[ANNOTATION_EDIT_URL];

    const cardClass = classes.gridItemCard;

    const cardContentClass = classes.gridItemCardContent;

    const entityLocation = entity.metadata.annotations?.[ANNOTATION_LOCATION];

    const allowRefresh =
        entityLocation?.startsWith('url:') || entityLocation?.startsWith('file:');
    const refreshEntity = useCallback(async () => {
        try {
            await catalogApi.refreshEntity(stringifyEntityRef(entity));
            alertApi.post({
                message: 'Refresh scheduled',
                severity: 'info',
                display: 'transient',
            });
        } catch (e) {
            errorApi.post(e as ErrorApiError);
        }
    }, [catalogApi, alertApi, errorApi, entity]);

    return (
        <Card className={cardClass}>
            <CardHeader
                title="About"
                action={
                    <>
                        {allowRefresh && canRefresh && (
                            <IconButton
                                aria-label="Refresh"
                                title="Schedule entity refresh"
                                onClick={refreshEntity}
                            >
                                <CachedIcon />
                            </IconButton>
                        )}
                        <IconButton
                            component={Link}
                            aria-label="Edit"
                            disabled={!entityMetadataEditUrl}
                            title="Edit Metadata"
                            to={entityMetadataEditUrl ?? '#'}
                        >
                            <EditIcon />
                        </IconButton>
                    </>
                }
            />
            <Divider />
            <CardContent className={cardContentClass}>
                <AboutContent entity={entity} />
            </CardContent>
        </Card>
    );
}