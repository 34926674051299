import { UserEntity } from '@backstage/catalog-model';
import { InfoCardVariants, Link } from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { Card, CardContent, CardHeader, Divider, Grid, makeStyles } from '@material-ui/core';
import { AboutField } from '@backstage/plugin-catalog';

const useStyles = makeStyles({
    gridItemCard: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 10px)', // for pages without content header
        marginBottom: '10px',
    },
    fullHeightCard: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    gridItemCardContent: {
        flex: 1,
    },
    fullHeightCardContent: {
        flex: 1,
    },
});

const HTSS_ANNOTATION = 'htssgroup.eu';

export const EntityUserHtssCard = (props: {
    variant?: InfoCardVariants;
    showLinks?: boolean;
}) => {
    const { entity: user } = useEntity<UserEntity>();
    if (!user) {
        return <Alert severity="error">User not found</Alert>;
    }

    const companyName = user.metadata.annotations?.[`${HTSS_ANNOTATION}/company-name`];
    const department = user.metadata.annotations?.[`${HTSS_ANNOTATION}/department`];
    const employeeId = user.metadata.annotations?.[`${HTSS_ANNOTATION}/employee-id`];
    const manager = user.metadata.annotations?.[`${HTSS_ANNOTATION}/manager`];
    const phoneNumber = user.metadata.annotations?.[`${HTSS_ANNOTATION}/phone-number`];

    const phoneHref = phoneNumber ? `tel:${phoneNumber}` : undefined;

    const classes = useStyles();

    let cardClass = '';
    if (props.variant === 'gridItem') {
        cardClass = classes.gridItemCard;
    } else if (props.variant === 'fullHeight') {
        cardClass = classes.fullHeightCard;
    }

    let cardContentClass = '';
    if (props.variant === 'gridItem') {
        cardContentClass = classes.gridItemCardContent;
    } else if (props.variant === 'fullHeight') {
        cardContentClass = classes.fullHeightCardContent;
    }

    return (
        <Card className={cardClass}>
            <CardHeader
                title="HTSS"
            />
            <Divider />
            <CardContent className={cardContentClass}>
                <Grid container>
                    <AboutField
                        label="Company"
                        value={companyName}
                        gridSizes={{ xs: 12, sm: 6, lg: 4 }}
                    />
                    <AboutField
                        label="Department"
                        value={department}
                        gridSizes={{ xs: 12, sm: 6, lg: 4 }}
                    />
                    <AboutField
                        label="Phone Number"
                        gridSizes={{ xs: 12, sm: 6, lg: 4 }}
                    >
                        <Link to={phoneHref ?? ''}>{phoneNumber}</Link>
                    </AboutField>
                    <AboutField
                        label="Manager"
                        value={manager}
                        gridSizes={{ xs: 12, sm: 6, lg: 4 }}
                    />
                    <AboutField
                        label="Employee ID"
                        value={employeeId}
                        gridSizes={{ xs: 12, sm: 6, lg: 4 }}
                    />
                </Grid>
            </CardContent>
        </Card>
    );

};